/* User Info */

#user_profile_stat {
    border-radius: 3px;
    padding: 0px 20px;
    display: flex;
    background-color: var(--clr-1);
  
  }
  
  #user_stat_indicator {
    margin-left: 30px;
    color: #009900;
  }
  
  #user_info {
    --user-profile-width: 35vw;
    padding: 20px 0px;
    width: 100vw;
    display: flex;
  }
  
  #user {
    margin: 40px;
    align-items: start;
    width: var(--user-profile-width);
  }
  
  #user_profile {
    border-radius: 5px;
    padding: 40px;
    /* Class: Center */
    flex-direction: column;
    background-color: var(--clr-1);
  }
  
  #profile_picture {
    --user_length: 120px;
    border: 3px solid black;
    border-radius: 50%;
    height: var(--user_length);
    width: var(--user_length);
    font-size: 40px;
    font-weight: bold;
    background-color: #FFFFFF;
  
  }
  
  #profile_username {
    margin: 20px 0px 10px 0px;
    font-size: 30px;
    font-weight: bold;
  }
  
  #profile_year {
    margin: 0px;
    font-weight: bold;
    color: white
  }
  
  #user_stats {
    margin: 40px;
    width: calc(100vw - var(--user-profile-width));
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  #user_stats tr {
    display: flex;
    justify-content: space-between;
  }
  
  #user_stats td {
    padding: 20px;
  }
  
  .user_stat_row {
    border-collapse: collapse;
    border-bottom: 1px solid black;
  }
  
  /* User Reviews */
  
  #user_reviews {
    width: 100%;
  }
  
  #user_review_table {
    margin: 30px;
    width: 80%;
    border-top: 1px dashed black;
  }