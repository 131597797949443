/*  || Registration */

.user_form {
  margin-bottom: 10px;
  border: 1px solid black;
  border-radius: 5px;
  padding: 30px;

  min-width: 200px;
  width: 60vw;
  max-width: 400px;
  display: flex;
  flex-direction: column;
  align-items: left;
  justify-content: center;
  box-shadow: 2px 2px 5px black;
}


.form_input_margin {
  margin-top: 25px;
}


.user_form input {
  height: 25px;
}

.user_form label {
  margin-bottom: 10px;
}

.input_requirements {
  color: grey;
}

.user_form_reminders {
  margin-bottom: 30px;
  border: 1px solid black;
  border-radius: 5px;
  padding: 10px 30px;

  min-width: 200px;
  width: 60vw;
  max-width: 400px;
  /* Class: center */
  flex-direction: column;
  box-shadow: 2px 2px 5px black;
}