#stat_awards {
    display: flex;
    flex-direction: column;
    justify-content: left;
    text-align: left;
  }

#winner_box {
    padding: 30px 0px;
    width: 100%;
    /* max-width: 2000px; */
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: var(--clr-1);
    font-size: 20px;
    font-weight: bold;
    color: #FFFFFF;
  }
  
  #stat_winner {
    font-size: 40px;
    text-shadow: -3px 3px #000000;
  }
  
  .winner_name {
    font-weight: bold;
    color: var(--clr-2);
    text-shadow: -2px 2px #000000;
  }
  
  #hydration_winner,
  #sunscreen_winner,
  #breakfast_winner {
    width: 300px;
    display: flex;
    justify-content: space-between;
  }