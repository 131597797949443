body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: var(--clr-1);
  /* font-family: monospace; */
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


/* CSS Ordering
  // The Box Design
  1. Margins
  2. Border
  3. Padding

  4. HTML Content Properties
    a. Height (normal, max, min)
    b. Width  (normal, max, min)
    c. Display 
    d. Background
    e. Text (size, weight, color, shadow)
    f.
    g.

  5. Box Properties 
    a. Position
    b. Z-index
    c. box-shadow

  6. Translations
*/

/* Color Palette
Blue Grey:  #4F687F
Cool Grey:  #D7CFCD
Ebony:      #383A3D
Peach:      #FDCA90
*/

/* || General CSS Elements & Classes */

#root {
  padding: 0px;
  margin: 0px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #FFFFFF;
  --clr-1: #383A3D;
  --clr-2: #FDCA90;
  --clr-3: #D7CFCD;
  --clr-4: #4F687F;
}

main {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #FFFFFF;
  z-index: 0;
  transform: translate(0px, 105px);
}

p {
  text-align: center;
}

.submit_button {
  margin-top: 20px;
  border-radius: 5px;
  padding: 10px 20px;

  display: flex;
  text-align: center;
  justify-content: center;

  background-color: var(--clr-1);
  font-size: 15px;
  font-weight: bold;
  color: var(--clr-2);
  text-decoration: none;
  text-shadow: -1px 2px black;
}

.peach_highlight {
  font-weight: bold;
  color: var(--clr-1);
  text-shadow: -2px 2px var(--clr-2);
}

.blue_highlight {
  font-weight: bold;
  color: var(--clr-2);
  text-shadow: -2px 2px black;
}

.highlight_title {
  margin: 50px;
  border-radius: 5px;
  padding: 5px 50px;

  text-align: center;
  background-color: var(--clr-1);
  color: var(--clr-2);
  font-weight: bold;
  font-size: 40px;
  text-shadow: -3px 3px #000000;
}

.center {
  display: flex;
  align-items: center;
  justify-content: center;
}

.error_message {
  margin: 5px;
  color: #AA0000;
  font-weight: bold;
}

.loader {
  position: fixed;
  top: 0px;
  left: 0px;
  height: 100vh;
  width: 100vw;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1;
}

.loader::after {
  content: "";
  width: 75px;
  height: 75px;
  border: 15px solid #dddddd;
  border-top-color: #009578;
  border-radius: 50%;
  animation: loading 0.75s ease infinite;
}

@keyframes loading {
  from {
    transform: rotate(0turn)
  }

  to {
    transform: rotate(1turn)
  }
}

.notification {
  --notification-bar-width: 60vw;
  border: 2px solid var(--clr-1);
  border-radius: 4px;
  padding: 0px 10px;
  width: var(--notification-bar-width);
  display: flex;
  text-align: center;
  justify-content: center;
  /* background-color: var(--clr-3); */
  background-color: #f4d8b9;
  font-weight: bold;
  color: black;
  position: fixed;
  left: calc(50vw - (var(--notification-bar-width) / 2));
  bottom: 10px;
  animation: notification 0.5s ease-out both;
}

.notification-in {
  animation-name: add-notification;
  animation-duration: 0.5s;
  animation-timing-function: ease-out;
}

.notification-out {
  animation-name: remove-notification;
  animation-duration: 0.5s;
  animation-timing-function: ease-in;
}

@keyframes add-notification {
  from {
    transform: translateY(100px);
  }

  to {
    transform: translateY(0px);
  }
}

@keyframes remove-notification {
  from {
    transform: translateY(0px);
  }

  to {
    transform: translateY(100px);
  }
}

.disclaimer-box {
  margin: 0px 10vw 10px 10vw;
  border: 1px solid var(--clr-3);
  border-radius: 5px;
  padding: 20px;
  /* box-shadow: 2px 2px -10px black; */
}

.disclaimer{
  /* padding: 0px 10vw; */
  font-size: 17px;
  text-align: center;
}

.stat_description {
  display: flex;
  flex-wrap: wrap;
}

.stat_description div {
  text-wrap: nowrap;
}

.stat_description p {
  text-align: left;
  padding: 0px;
  margin: 0px 0px 10px 0px;
}

.spacer {
  width: 100vw;
  overflow: hidden;
  aspect-ratio: 2000/200;
  background-repeat: repeat-x;
  background-size: auto;
}

.winner_box_top {
  height: 150px;
  background-position: bottom;
  background-image: url('../assets/winner_box_top_21.svg');
}

.winner_box_bottom {
  height: 150px;
  background-position: top;
  background-image: url('../assets/winner_box_bottom_21.svg');
} 


.centered-text-box {
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: center;
  display: flex;
  flex-wrap: wrap;
}

.centered-text-box p {
  margin: 5px 4px;
}

/* .footer {
  position: fixed;
  bottom: 0px;
} */