/* Stats Timer */

#stat_timer_section {
    width: 100%;
    height: 200px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  
    font-weight: bold;
    color: #FFFFFF;
    background-color: var(--clr-1);
  }
  
  #stat_timer {
    width: 260px;
    display: flex;
    justify-content: space-between;
  
  }
  
  #stat_timer div {
    border: 2px solid #FFFFFF;
    padding: 10px;
  
    width: 50px;
    display: flex;
    text-align: center;
    justify-content: center;
    background-color: black;
  
    font-size: 40px;
    font-weight: bold;
    color: var(--clr-2)
  }