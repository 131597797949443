/* || BANNER */

#banner {
  /* box-shadow: 4px 4px 4px grey; */
  padding: 0px 20px;
  width: calc(100vw - 40px);
  display: flex;
  align-items: center;
  background-color: var(--clr-1);
  position: fixed;
  top: 0%;
  z-index: 1;
}

#logo_div {
  width: 25%;
  min-width: 200px;
}

#logo {
  margin: 10px 10px 10px 0px;
  /* 100/240 ratio */
  height: 83px;
  width: 200px;
}

#menu {
  margin-left: 20px;
  width: 50%;
  /* Class: center */
  flex-wrap: wrap;
}

#menu a {
  margin: 0px 10px;
  padding: 10px;
  font-size: 20px;
  color: var(--clr-2);
  text-shadow: -2px 2px #000000;
}

@media all and (max-width: 715px) {
  #menu {
    margin-left: 20px;
    display: flex;
    justify-content: right;
  }

}

#banner_user_div {
  width: 25%;
  min-width: 100px;
  display: flex;
  justify-content: right;
  position: relative;
}

#banner_user {
  flex-wrap: wrap;
}

#banner_user a {
  margin: 0px 20px;
  border: 2px solid black;
  border-radius: 50%;

  height: 50px;
  width: 50px;
  background-color: #FFFFFF;
  font-weight: bold;
  color: black;
  text-decoration: none;
}

#banner_login {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}

.banner_button {
  margin: 3px 5px;
  border-radius: 2px;
  height: 40px;
  width: 70px;
  font-size: 15px;
  text-decoration: none;
  cursor: pointer;
}

#banner_login_button,
#banner_logout_button {
  background-color: var(--clr-3);
}

#banner_signup_button {
  background-color: #FFFFFF;
  text-wrap: nowrap;
}

#dropdown_menu_button {
  height: 50px;
  width: 50px;
  border-radius: 5px;
  /* background-color: rgba(0, 0, 0, 0.2); */
}

#dropdown_menu {
  padding: 0px;
  border: 1px solid var(--clr-1);
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  position: absolute;
  transform: translate(-65px, 10px);
  background-color: var(--clr-3);
  text-align: left;
  z-index: 2;
  box-shadow: 2px 2px 5px black;
}

#dropdown_menu a {
  padding: 10px 20px;
  font-size: 20px;
  color: var(--clr-1);
  text-shadow: -2px 2px var(--clr-2);
  font-weight: bold;
  animation: faze-text 0.6s ease-in-out;
  animation-fill-mode: both;
}


@keyframes faze-text {
  0% {
    opacity: 0%;
  }

  50% {
    opacity: 100%;
    color: rgba(0, 0, 0, 0);
    text-shadow: 0px 0px var(--clr-1);
  }

  100% {
    color: var(--clr-1);
    text-shadow: -2px 2px var(--clr-2);
  }
}

/* .dropdown_text_fade {
  animation-name: faze-text;
  animation-duration: 0.6s;
  animation-timing-function: ease-in-out;
} */

#dropdown_menu a:nth-child(1) { animation-delay: 0s; }
#dropdown_menu a:nth-child(2) { animation-delay: 0.2s; }
#dropdown_menu a:nth-child(3) { animation-delay: 0.4s; }

