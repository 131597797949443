

.change_log {
    width: 80vw;
    border: 1px solid var(--clr-3);
    border-radius: 10px;
    box-shadow: 2px 2px 5px black;
    animation: fade-slide-in 0.5s ease-in-out;
    animation-fill-mode: both;
}

.change_log_header {
    width: 100%;
    border-top-right-radius: 10px;
    border-top-left-radius: 10px;
    background-color: var(--clr-1);
    display: flex;
    text-align: center;
    justify-self: center;
    font-size: 18px;
    gap: 10px;
}

@media all and (max-width: 600px) {
    .change_log_header {
        padding: 10px 0px;
        flex-direction: column;
    }
    .change_log_header p {
        padding: 0px;
        margin: 0px;
    }
}

.change_log_description {
    text-align: left;
}

.change_log_body {
    padding: 10px 40px;
}

.change_log_text li {
    padding: 5px 0px;
}


@keyframes fade-slide-in {
    from {
        opacity: 0%;
        transform: translate(-30px, 0px);
    }

    to {
        opacity: 100%;
        transform: translate(0px, 0px);
    }
}

.change_log div:nth-child(1) { animation-delay: 0s;}
.change_log div:nth-child(2) { animation-delay: 0.2s;}
.change_log div:nth-child(3) { animation-delay: 0.4s;}
